if (typeof window !== 'undefined') {
  (function ($) {
    'use strict';

    // Mean Menu
    $('.mean-menu').meanmenu({
      meanScreenWidth: '991',
    });

    // Header Sticky
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 120) {
        $('.navbar-area').addClass('is-sticky');
      } else {
        $('.navbar-area').removeClass('is-sticky');
      }
    });

    // Go to Top
    $(function () {
      // Scroll Event
      $(window).on('scroll', function () {
        var scrolled = $(window).scrollTop();
        if (scrolled > 600) $('.go-top').addClass('active');
        if (scrolled < 600) $('.go-top').removeClass('active');
      });
      // Click Event
      $('.go-top').on('click', function () {
        $('html, body').animate({ scrollTop: '0' }, 500);
      });
    });
  })(jQuery);
}
